import { useEffect, useRef} from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components"
import { useMediaDevices, useUserMediaFromDevices } from "../../modules/camera/use-camera";

type Props = {}

const Container = styled.div`

flex: 1 0 100%;

    display: flex;
    flex-direction: column;

     .video-container {
        flex: 1 1 100%;
        position: relative;
    }

    video {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;


const CameraComponent: React.FC<Props> = () => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const { register, watch } = useForm();
    const { videoInputDevices } = useMediaDevices();
    const videoDeviceId = watch('source', videoInputDevices[0] ? videoInputDevices[0].deviceId : null);
    const { srcObject } = useUserMediaFromDevices(videoDeviceId);

    useEffect(() => {
        if (videoRef.current && srcObject) {
            videoRef.current.srcObject = srcObject
        }
    }, [videoRef, srcObject])

    return <Container>
        <div className="video-container">
            <video ref={videoRef} autoPlay={true}></video>

        </div>
        {videoDeviceId}
        <select name="source" ref={register}>
            {videoInputDevices.map((deviceInfo, index) => {
                return <option defaultChecked={index === 0} value={deviceInfo.deviceId} key={index}>{deviceInfo.label || `camera ${index}`}</option>
            })}
        </select>
    </Container>
}

export default CameraComponent;
