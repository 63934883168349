import { MainModuleState, MainModuleAction, MAIN_MODULE_ACTION } from ".";
import getGamePointsBerings from "../../utils/geo/get-game-points-berings";
import { getPoint } from "../../utils/geo/get-point";

export default function UserReducer(state: MainModuleState, action: MainModuleAction): MainModuleState {
    const { type, payload } = action;
    switch (type) {
        case MAIN_MODULE_ACTION.COMPLETE_POINT:
            return {
                ...state,
                gamePoints: state.gamePoints.map(p => {
                    if (p.id === payload.id) {
                        return {
                            ...p,
                            completed: true
                        }
                    }

                    return p;
                })
            }

        case MAIN_MODULE_ACTION.SET_GAME_POINT:

            return {
                ...state,
                gamePoints: getGamePointsBerings()
                    .map(v => getPoint(v, payload.distance, payload.position))
                    .filter(Boolean as any as <T>(x: T | null) => x is T)
                    .map((v, index) => {
                        return {
                            ...v,
                            id: `${index}`,
                            completed: false
                        }
                    })
            }

        case MAIN_MODULE_ACTION.SET_POINTS_IN_LIPKOW:
            return {
                ...state,
                gamePoints: [
                    [52.28031610294377, 20.80738869265754],
                    [52.28085433858946, 20.808389156588472],
                    [52.28022913132655, 20.808692246197577],
                    [52.2805688119574, 20.807799070623847],
                    [52.2799879072685, 20.807852714802454],
                    [52.280296237428615, 20.810002032618648],
                    [52.28075873027899, 20.80922055564246],
                    [52.2802513538993, 20.80922055564246],
                    [52.28054212030446, 20.808388043435176],
                    [52.280190858635635, 20.808244506847714],
                    [52.27968542723783, 20.809169520411363],
                    [52.281092424654325, 20.809437455374628],
                    [52.28006596492684, 20.810040309041966],
                    [52.28061822663239, 20.807029230407192],
                    [52.279589804432995, 20.80810097026025],
                    [52.280575294873714, 20.80900684561224],
                    [52.28028843160071, 20.807807517681436]
                ].map((p, index) => {
                    return {
                        lat: p[0],
                        lng: p[1],
                        id: `${index}`,
                        completed: false
                    }
                })
            }
    }

    return state;
}
