import { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components"
import useGeo from "../../modules/geo/hooks/use-geo";
import useGamePoints from "../../modules/main/hooks/use-game-points";
import getDistance from "../../utils/geo/get-distance";
import { useWhatChanged } from '@simbathesailor/use-what-changed';

declare const google: any;

type Props = {}

const Container = styled.div`
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;


    display: flex;
    align-items: stretch;

    #map {
        flex: 1 1 100%;
    }
`;

type LatLng = { lat: number, lng: number }

const MapComponent: React.FC<Props> = () => {

    const mapContainer = useRef<HTMLDivElement>(null);
    const { position, hasPosition } = useGeo();
    const posMarker = useRef<any>();
    const mapRef = useRef<any>();


    const [startPosition, setStartPosition] = useReducer((prevState: LatLng | null, action: LatLng | null) => {
        return prevState || action;
    }, null);

    const points = useGamePoints();

    useEffect(() => {
        if (position) {
            setStartPosition(position);
        }
    }, [position, setStartPosition])

    useEffect(() => {
        console.log('new MAP');
        mapRef.current = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 52.2110, lng: 20.9752 },
            zoom: 10,
            mapTypeId: 'satellite'
        });
    }, [mapRef])

    useEffect(() => {
        if (mapRef.current && startPosition) {
            console.log('create pos Marker');
            posMarker.current = new google.maps.Marker({
                icon: '//maps.google.com/mapfiles/kml/shapes/man.png',
                position: startPosition,
                map: mapRef.current
            });
        }
    }, [mapRef, startPosition])

    useEffect(() => {
        if (mapRef.current) {

            points.forEach(({ lat, lng }) => {
                console.log('create game marker', lat, lng)
                new google.maps.Marker({
                    position: {
                        lat,
                        lng
                    },
                    map: mapRef.current
                });
            })

        }

    }, [mapRef, points]);

    useEffect(() => {
        if (posMarker.current && position) {
            console.log('move position marker', position)
            posMarker.current.setPosition(position)
        }
    }, [posMarker, position]);



    return <Container>
        <div id="map" ref={mapContainer}></div>
    </Container>
}

export default MapComponent;
