import { useEffect } from "react";
import { useGeoModuleDispatch, GEO_MODULE_ACTION } from ".";

type Props = {}

const ListenersComponent: React.FC<Props> = ({children}) => {
    const dispatch = useGeoModuleDispatch();

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.watchPosition((position) => {
                console.log('WATCH', position)
                dispatch({
                    type: GEO_MODULE_ACTION.SET_DATA,
                    payload: {
                        position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        accuracy: position.coords.accuracy
                    }
                })
            }, () => {}, {
                maximumAge: 500,
                enableHighAccuracy: true
            });

            navigator.geolocation.getCurrentPosition((position) => {
                console.log('INIT', position)
                dispatch({
                    type: GEO_MODULE_ACTION.SET_DATA,
                    payload: {
                        position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        accuracy: position.coords.accuracy
                    }
                })

            }, () => {}, {
                maximumAge: 500,
                enableHighAccuracy: true
            });
        }
    }, [dispatch])

    return <>{children}</>;
}

export default ListenersComponent;
