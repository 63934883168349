import { GeoModuleAction, GeoModuleState, GEO_MODULE_ACTION } from ".";

export default function UserReducer(state: GeoModuleState, action: GeoModuleAction): GeoModuleState {
    const { type, payload } = action;
    switch (type) {
        case GEO_MODULE_ACTION.SET_DATA:
            console.log('GEO_MODULE_ACTION.SET_DATA: ', payload)
            return {
                ...state,
                ...payload
            }
    }

    return state;
}
