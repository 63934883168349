import { useGeoModuleState } from "..";

export default function useGeo() {
    const {position, accuracy} = useGeoModuleState();

    return {
        position,
        accuracy,
        hasPosition: Boolean(position)
    }
}
