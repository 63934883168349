import makeStore from "@mborecki/react-create-store";
import React from "react";
import ListenersComponent from "./listeners-componen";
import reducer from "./reducer";

export type GeoModuleState = {
    position?: {
        lat: number,
        lng: number
    },
    accuracy?: number
}

const initGeoModuleState = {
}

export enum GEO_MODULE_ACTION {
    SET_DATA,
}

export type GeoModuleAction = {
    type: GEO_MODULE_ACTION,
    payload?: any
}

const [
    GeoModuleProvider,
    useGeoModuleDispatch,
    useGeoModuleState
] = makeStore(reducer, initGeoModuleState)

const Provider: React.FC<{}> = ({ children }) => {

    return <GeoModuleProvider>
        <ListenersComponent>
            {children}
        </ListenersComponent>
    </GeoModuleProvider>
}


export {
    Provider as GeoModuleProvider,
    useGeoModuleDispatch,
    useGeoModuleState
}
