import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components"
import useGeo from "../../modules/geo/hooks/use-geo";
import { MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main";
import useGamePoints from "../../modules/main/hooks/use-game-points";
import getDistance from "../../utils/geo/get-distance";

type Props = {}

const Container = styled.div`
    td {
        text-align: center;
        padding: 2px;
        vertical-align: middle;
        border: 1px solid black;
    }

    label {
        margin: 1em;
        display: block;

        input {
            display: inline-block;
        }
    }
`;

const GameControlls: React.FC<Props> = () => {

    const { position, accuracy } = useGeo();
    const dispath = useMainModuleDispatch();
    const points = useGamePoints();
    const { register, watch } = useForm();

    const distance = watch('distance', 80);

    const makeNewPoint = useCallback(() => {
        if (position) {
            console.log({ position, distance })
            dispath({
                type: MAIN_MODULE_ACTION.SET_GAME_POINT,
                payload: {
                    position,
                    distance: parseInt(distance) / 1000
                }
            });
        }
    }, [position, distance]);

    const makeNewStaticPoints = useCallback(() => {
        if (position) {
            console.log({ position, distance })
            dispath({
                type: MAIN_MODULE_ACTION.SET_POINTS_IN_LIPKOW,
                payload: {}
            });
        }
    }, [position, distance]);


    return <Container>

        <h2>Kontrola gry</h2>
        {!position && <p>Czekam na określenie pozycji</p>}
        {position && <div>
            <div>
                <label>Promień w jakim mają być punkty:<br />
                    <input defaultValue={80} type="range" min={10} max={300} step={10} name='distance' ref={register} />
                    {distance}
                </label>

            </div>
            <button onClick={makeNewPoint}>Wyznacz nowe punkty</button>
            <button onClick={makeNewStaticPoints}>Wyznacz nowe punkty na polanie</button>
        </div>
        }


        <div>
            <h2>Aktulna pozycja</h2>
            <p>LAT: {position?.lat}</p>
            <p>LNG: {position?.lng}</p>
            <p>ACCURACY [m]: {accuracy}</p>
        </div>

        <h3>Odległości między punktami [m]</h3>
        <table>
            <tbody>
                <tr>
                    <td>x</td>
                    {points.map(p => {
                        return <td key={p.id}>{p.id}</td>
                    })}
                </tr>
                {
                    points.map(p1 => {
                        return <tr key={p1.id}>
                            <td>{p1.id}</td>
                            {
                                points.map(p2 => {
                                    const d = getDistance(p1, p2)
                                    return <td key={p2.id}>{d === 0 ? 'x' : (d*1000).toFixed(2)}</td>
                                })
                            }
                        </tr>
                    })
                }
            </tbody>
        </table>


        {points && <div>
            <h2>Punkty gry</h2>
            {points.map((p, index) => {
                return <p key={index}>{p.id} {p.lat} {p.lng} {p.completed ? 'zaliczony' : 'niezaliczony'}</p>
            })}
        </div>}


    </Container>
}

export default GameControlls;
