import { useEffect, useMemo, useState } from "react";

export function useMediaDevices() {

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            setError("enumerateDevices() not supported.");
            setLoading(false);
            return;
        }

        navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => {
                setDevices(deviceInfos);
                setLoading(false);
            })
    }, [setDevices, setError, setLoading]);

    const audioInputDevices = useMemo(() => {
        return devices.filter(d => d.kind === 'audioinput')
    }, [devices])

    const audioOutputDevices = useMemo(() => {
        return devices.filter(d => d.kind === 'audiooutput')
    }, [devices])

    const videoInputDevices = useMemo(() => {
        return devices.filter(d => d.kind === 'videoinput')
    }, [devices])

    return {
        devices,
        error,
        isLoading,
        audioInputDevices,
        audioOutputDevices,
        videoInputDevices
    }
}

export function useUserMediaFromDevices(videoDeviceId: string | null = null, audioDeviceId: string | null = null) {
    const constraints = useMemo(() => {
        const constraints: MediaStreamConstraints = {};

        if (videoDeviceId) {
            constraints.video = {
                deviceId: {
                    exact: videoDeviceId
                }
            }
        }

        if (audioDeviceId) {
            constraints.audio = {
                deviceId: {
                    exact: audioDeviceId
                }
            }
        }

        return constraints;
    }, [audioDeviceId, videoDeviceId]);

    const [srcObject, setSrc] = useState<MediaStream>();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia(constraints)
            .then(srcObject => {
                setLoading(false);
                setSrc(srcObject);
            })
            .catch((err) => {
                console.error(err);
                setError('UserMedia Error');
            })
    }, [setSrc, constraints])

    return {
        isLoading,
        error,
        srcObject
    }
}
