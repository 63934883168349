import { useCallback, useEffect, useState } from "react";

export default function useOrintationEvents() {

    const [deviceOrientation, setDeviceOrientation] = useState<number | null>();
    const [deviceOrientationAbsolute, setDeviceOrientationAbsolute] = useState<number | null>();


    const onDeviceOrientationEvent = useCallback((event: DeviceOrientationEvent) => {
        setDeviceOrientation(event.alpha);
    }, [setDeviceOrientation])

    const onDeviceOrientationAbsoluteEvent = useCallback((event: DeviceOrientationEvent) => {
        setDeviceOrientationAbsolute(event.alpha);
    }, [setDeviceOrientationAbsolute])


    useEffect(() => {
        window.addEventListener('deviceorientation', onDeviceOrientationEvent);
        window.addEventListener('deviceorientationabsolute', onDeviceOrientationAbsoluteEvent);

        return () => {
            window.removeEventListener('deviceorientation', onDeviceOrientationEvent);
            window.removeEventListener('deviceorientationabsolute', onDeviceOrientationAbsoluteEvent);
        }

    }, [onDeviceOrientationEvent, onDeviceOrientationAbsoluteEvent])


    return {
        deviceOrientation,
        deviceOrientationAbsolute
    }
}
