import { useCallback } from "react";
import styled from "styled-components";
import useGeo from "../../modules/geo/hooks/use-geo";
import { MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main";
import useGamePoints from "../../modules/main/hooks/use-game-points";
import getDistance from "../../utils/geo/get-distance";

type Props = {}

const Container = styled.div`

`;

const PointsGame: React.FC<Props> = () => {

    const points = useGamePoints();
    const { position, accuracy } = useGeo();
    const dispatch = useMainModuleDispatch();

    const completePoint = useCallback((id: string) => {
        dispatch({
            type: MAIN_MODULE_ACTION.COMPLETE_POINT,
            payload: {id}
        })
    }, [dispatch])

    return <Container>
        {position && accuracy && <div>
            <table>
                <p>Aktualna dokładność pomiaru: {accuracy}m</p>
                <tbody>
                    <tr>
                        <td>Punkt</td>
                        <td>Odległość od punktu</td>
                        <td>Akcja</td>
                    </tr>

                    {points.map(p => {
                        const dist = getDistance(p, position) * 1000
                        return <tr key={p.id + p.lat}>
                            <td>{p.id}</td>
                            <td>{dist.toFixed(0)}m</td>
                            <td>
                                {p.completed ? 'ZALICZONY' : <button onClick={() => completePoint(p.id)} disabled={dist > accuracy}>ZNALAZŁEM PUNKT</button>}
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
        }
    </Container >
}

export default PointsGame;
