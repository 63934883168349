const POINTS_COUNT = 5;

export default function getGamePointsBerings(): number[] {
    const padding = Math.random() * 360;

    const mainAngle = 360 / POINTS_COUNT;
    const zoneWidth = mainAngle * .7;

    return new Array(POINTS_COUNT)
        .fill(null)
        .map((_, index) => {
            return padding + mainAngle*index + (Math.random() * zoneWidth) - zoneWidth/2;
        });
}
