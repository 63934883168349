import { useMemo } from "react";
import styled from "styled-components"
import useGeo from "../../modules/geo/hooks/use-geo";
import useGamePoints from "../../modules/main/hooks/use-game-points";
import getDistance from "../../utils/geo/get-distance";
import useOrintationEvents from "./use-orientation-events";

type Props = {}

const Container = styled.div`

`;

const CompassComponent: React.FC<Props> = () => {

    const { deviceOrientation, deviceOrientationAbsolute } = useOrintationEvents();
    const { position, accuracy } = useGeo();
    const points = useGamePoints();

    const distances = useMemo(() => {
        if (position) {
            return points.map(p => {
                const { lat, lng } = position;
                return getDistance({
                    lat,
                    lng
                }, p).toFixed(3)
            })
        }
    }, [position, points])

    return <Container>
        <h2>Kompass</h2>
        <div className="panel">
            <p className="name">deviceOrientation</p>
            <p className="value">{deviceOrientation ? deviceOrientation.toFixed(2) : 'null'}</p>
        </div>
        <div className="panel">
            <p className="name">deviceOrientationAbsolute</p>
            <p className="value">{deviceOrientationAbsolute ? deviceOrientationAbsolute.toFixed(2) : 'null'}</p>
        </div>
        <h2>Lokalizacja</h2>
        <div className="panel">
            <p className="name">Szerokość</p>
            <p className="value">{position ? position.lat.toFixed(4) : 'null'}</p>
        </div>
        <div className="panel">
            <p className="name">Długość</p>
            <p className="value">{position ? position.lng.toFixed(4) : 'null'}</p>
        </div>
        <div className="panel">
            <p className="name">Dokładność (m)</p>
            <p className="value">{accuracy ? accuracy.toFixed(2) : 'null'}</p>
        </div>
        <h2>Punkt</h2>

        <div className="panel">
            <p className="name">Odleglość</p>
            <p className="value">{distances?.join(' - ')}</p>
        </div>

    </Container>
}

export default CompassComponent;
