import React, { useReducer } from 'react';
import styled from 'styled-components';
import CameraComponent from '../../components/camera';
import CompassComponent from '../../components/compass';
import GameControlls from '../../components/game-controlls';
import MapComponent from '../../components/map';
import PointsGame from '../../components/points-game';

const Container = styled.div`

    width: 100vw;
    height: 100%;
    max-height: 100vh;


    display: flex;
    flex-direction: column;

    .content {
        flex: 0 1 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        overflow-y: scroll
    }

    .buttons {
        flex: 1 0 0;
        min-height: 10vh;

        display: flex;
        align-items: stretch;

        &>* {
            flex: 1 1 20%;
        }
    }
`;

enum TABS {
    COMPASS,
    MAP,
    CAMERA,

    GAME_CONTROLS,

    POINTS_GAME
}

export default function HomePage() {

    const [tab, setTab] = useReducer((state: TABS, newState: TABS) => {
        return newState;
    }, TABS.GAME_CONTROLS)

    return (
        <Container>

            <div className="content">
                {tab === TABS.CAMERA && <CameraComponent />}
                {tab === TABS.COMPASS && <CompassComponent />}
                {tab === TABS.MAP && <MapComponent />}
                {tab === TABS.GAME_CONTROLS && <GameControlls />}
                {tab === TABS.POINTS_GAME && <PointsGame />}
            </div>

            <div className="buttons">
                <button onClick={() => setTab(TABS.CAMERA)}>Kamera</button>
                <button onClick={() => setTab(TABS.MAP)}>Mapa</button>
                <button onClick={() => setTab(TABS.COMPASS)}>Kompas</button>
                <button onClick={() => setTab(TABS.GAME_CONTROLS)}>Ustawienia gry</button>
                <button onClick={() => setTab(TABS.POINTS_GAME)}>Gra w punkty</button>
            </div>
        </Container>
    )
}
