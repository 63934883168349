import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

const CONFIG_MODULE_LS_KEY = 'page_config'

export type MainModuleState = {
    isLoading: boolean,
    gamePoints: {
        id: string,
        completed: boolean,
        lat: number,
        lng: number
    }[]
}

const initMainModuleState = {
    isLoading: false,
    gamePoints: []
}

export enum MAIN_MODULE_ACTION {
    SET_GAME_POINT,

    COMPLETE_POINT,

    SET_POINTS_IN_LIPKOW
}

export type MainModuleAction = {
    type: MAIN_MODULE_ACTION,
    payload?: any
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = makeStore(reducer, initMainModuleState, CONFIG_MODULE_LS_KEY)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}
